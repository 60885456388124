// import React, { useState, useEffect, Fragment } from "react"
// import { useAuth0 } from "@auth0/auth0-react"
// import { useTranslation } from "react-i18next"
// import { Storage } from "@capacitor/storage"
// import {
//   EntryForm,
//   LabelBz,
//   BloodDrop,
//   Blood,
//   DataInput,
//   LabelCa,
//   Carbohydrates,
//   Donut,
//   LabelIu,
//   InsulinSelect,
//   InsulinOption,
//   LabelFa,
//   FactorSelect,
//   FactorOption,
//   Button,
//   InsulinUnits,
//   FullInject,
//   EmptyInject,
//   SuccessMessage,
// } from "./HomeInputStyles"

// import carbohydratesImg from "../../public/carbohydratesImg.png"
// import donut from "../../public/donut.png"
// import blood from "../../public/blood.PNG"
// import blooddrop from "../../public/blooddrop.PNG"
// import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
// import Loading from "../PageLoader/PageLoader"
// import handleInsulinUnit from "../../utils/calculate"

// const saveToLocalStorage = async (key, value) => {
//   await Storage.set({
//     key,
//     value: JSON.stringify(value),
//   })
// }

// const getFromLocalStorage = async (key) => {
//   const result = await Storage.get({ key })
//   return result.value ? JSON.parse(result.value) : null
// }

// export default function HomeInput() {
//   const { user, isLoading } = useAuth0()
//   const { t } = useTranslation()
//   const [bloodsugar, setBloodsugar] = useState("")
//   const [carbohydrates, setCarbohydrates] = useState("")
//   const [insulin, setInsulin] = useState("")
//   const [value, setValue] = useState("")
//   const [showPopup, setShowPopup] = useState(false)
//   const [showSuccess, setShowSuccess] = useState(false)
//   const [formData, setFormData] = useState(null)
//   const [insulinFactor, setInsulinFactor] = useState(null)
//   const [correctionFactors, setCorrectionFactors] = useState(null)
//   const [offlineData, setOfflineData] = useState([])

//   useEffect(() => {
//     const fetchFactors = async () => {
//       const localInsulinFactor = await getFromLocalStorage("insulinFactors")
//       const localCorrectionFactors = await getFromLocalStorage("correctionFactors")
//       const localOfflineData = await getFromLocalStorage("offlineData")

//       if (localInsulinFactor) setInsulinFactor(localInsulinFactor)
//       if (localCorrectionFactors) setCorrectionFactors(localCorrectionFactors)
//       if (localOfflineData) setOfflineData(localOfflineData)

//       if (navigator.onLine && user) {
//         try {
//           const insulinResponse = await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${user.email}`)
//           const correctionResponse = await fetch(
//             `https://insulinapp-api.vercel.app/api/correctionfactors/${user.email}`,
//           )

//           if (insulinResponse.ok) {
//             const insulinData = await insulinResponse.json()
//             setInsulinFactor(insulinData)
//             await saveToLocalStorage("insulinFactors", insulinData)
//           }

//           if (correctionResponse.ok) {
//             const correctionData = await correctionResponse.json()
//             setCorrectionFactors(correctionData)
//             await saveToLocalStorage("correctionFactors", correctionData)
//           }
//         } catch (error) {
//           console.error("Error fetching factors:", error)
//         }
//       }
//     }

//     fetchFactors()
//   }, [user])

//   useEffect(() => {
//     window.addEventListener("online", syncOfflineData)
//     return () => {
//       window.removeEventListener("online", syncOfflineData)
//     }
//   }, []) // Removed unnecessary dependency: offlineData

//   if (isLoading || !user || !insulinFactor || !correctionFactors) return <Loading />

//   function handleUserDayFactor(insulinFactor) {
//     const currentHour = new Date().getHours()
//     if (currentHour >= 6 && currentHour < 11) return insulinFactor?.morningfactor || 1
//     if (currentHour >= 11 && currentHour < 17) return insulinFactor?.lunchfactor || 1
//     if (currentHour >= 17 && currentHour < 22) return insulinFactor?.eveningfactor || 1
//     return insulinFactor?.latefactor || 1
//   }

//   function handleUserCorrectionFactor(correctionFactors) {
//     const currentHour = new Date().getHours()
//     if (currentHour >= 6 && currentHour < 11) return correctionFactors?.morningcorrectionfactor || 1
//     if (currentHour >= 11 && currentHour < 17) return correctionFactors?.lunchcorrectionfactor || 1
//     if (currentHour >= 17 && currentHour < 22) return correctionFactors?.eveningcorrectionfactor || 1
//     return correctionFactors?.latecorrectionfactor || 1
//   }

//   async function handleSubmit(event) {
//     event.preventDefault()

//     const daytimeFactor = handleUserDayFactor(insulinFactor)
//     const correctionFactor = handleUserCorrectionFactor(correctionFactors)
//     const calculateUnits = handleInsulinUnit(bloodsugar, carbohydrates, daytimeFactor, correctionFactor)

//     setFormData({ bloodsugar, carbohydrates, insulin, daytimeFactor, correctionFactor, calculateUnits })
//     setValue(calculateUnits)
//     setShowPopup(true)
//   }

//   async function confirmSave() {
//     const cardData = {
//       bloodsugar: formData.bloodsugar,
//       carbohydrates: formData.carbohydrates,
//       insulin: formData.insulin,
//       daytimeFactor: formData.daytimeFactor,
//       correctionFactor: formData.correctionFactor,
//       calculateUnit: formData.calculateUnits,
//       userMail: user.email,
//       date: new Date().toLocaleString("de-DE", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit",
//       }),
//     }

//     if (navigator.onLine) {
//       try {
//         const response = await fetch("https://insulinapp-api.vercel.app/api/insulindata", {
//           method: "POST",
//           headers: { "content-type": "application/json" },
//           body: JSON.stringify(cardData),
//         })

//         if (response.ok) {
//           resetForm()
//         } else {
//           throw new Error("Failed to save data")
//         }
//       } catch (error) {
//         console.error("Error saving data:", error)
//         await saveOfflineData(cardData)
//       }
//     } else {
//       await saveOfflineData(cardData)
//     }
//   }

//   async function saveOfflineData(data) {
//     const newOfflineData = [...offlineData, data]
//     setOfflineData(newOfflineData)
//     await saveToLocalStorage("offlineData", newOfflineData)
//     resetForm()
//   }

//   function resetForm() {
//     setBloodsugar("")
//     setCarbohydrates("")
//     setInsulin("")
//     setTimeout(() => setValue(""), 15000)
//     setShowPopup(false)
//     setShowSuccess(true)
//     setTimeout(() => setShowSuccess(false), 3000)
//   }

//   async function syncOfflineData() {
//     const localOfflineData = await getFromLocalStorage("offlineData")
//     if (localOfflineData && localOfflineData.length > 0) {
//       for (const data of localOfflineData) {
//         try {
//           const response = await fetch("https://insulinapp-api.vercel.app/api/insulindata", {
//             method: "POST",
//             headers: { "content-type": "application/json" },
//             body: JSON.stringify(data),
//           })
//           if (!response.ok) {
//             throw new Error("Failed to sync data")
//           }
//         } catch (error) {
//           console.error("Error syncing offline data:", error)
//           return // Stop syncing if there's an error
//         }
//       }
//       // Clear offline data after successful sync
//       setOfflineData([])
//       await saveToLocalStorage("offlineData", [])
//     }
//   }

//   return (
//     <>
//       {showPopup && <ConfirmationPopup data={formData} onConfirm={confirmSave} onCancel={() => setShowPopup(false)} />}
//       {showSuccess && <SuccessMessage className="success-message">{t("dataSaved")}</SuccessMessage>}

//       <EntryForm onSubmit={handleSubmit}>

//         <LabelBz htmlFor="bloodsugar">
//           {t("bloodsugar")} <br /> mg/dl
//           <BloodDrop src={blooddrop} alt="blooddrop" />
//           <Blood src={blood} alt="blood" />
//           <DataInput
//             type="decimal"
//             name="bloodsugar"
//             placeholder={t("currentbloodglucosevalue")}
//             id="bloodsugar"
//             min={0}
//             inputMode="numeric"
//             maxLength={3}
//             required
//             value={bloodsugar}
//             onChange={(e) => setBloodsugar(e.target.value)}
//           />
//         </LabelBz>
//         <LabelCa htmlFor="carbohydrates">
//           {t("carbohydrates")} (Khd) <br /> g (Gramm)
//           <Carbohydrates src={carbohydratesImg} alt="carbohydrates" />
//           <Donut src={donut} alt="donut" />
//           <DataInput
//             type="decimal"
//             name="carbohydrates"
//             placeholder={t("howmanyKhd65g")}
//             id="carbohydrates"
//             maxLength={3}
//             min={0}
//             inputMode="numeric"
//             required
//             value={carbohydrates}
//             onChange={(e) => setCarbohydrates(e.target.value)}
//           />
//         </LabelCa>
//         <Fragment>
//           <LabelIu htmlFor="insulin">
//             {t("whichinsulin1")} <br /> {t("whichinsulin2")}
//             <InsulinSelect
//               name="setinsulinSelect"
//               id="setinsulinSelect"
//               value={insulin}
//               onChange={(e) => setInsulin(e.target.value)}
//             >
//               <InsulinOption value="">{t("chooseinsulin")}</InsulinOption>
//               {[
//                 { value: "Fiasp", label: "Fiasp" },
//                 { value: "Hum Normal", label: "Hum Normal" },
//               ].map((option) => (
//                 <InsulinOption key={option.value} value={option.value}>
//                   {option.label}
//                 </InsulinOption>
//               ))}
//             </InsulinSelect>
//           </LabelIu>
//         </Fragment>
       
        


//         <Button type="submit">{t("confirm")}</Button>
//         <InsulinUnits>
//           {value} / {t("injectunits")} <br /> {t("ofinsulin")}
//           <FullInject src="/volleSpritze.png" alt="Full syringe" />
//           <EmptyInject src="/leereSpritze.png" alt="Empty syringe" />
//         </InsulinUnits>
//         <LabelFa htmlFor="factor">
//           {t("daytimefactor")}
//           <FactorSelect name="dayfactorSelect" id="dayfactorSelect" value={handleUserDayFactor(insulinFactor)}>
//             <FactorOption value={handleUserDayFactor(insulinFactor)}>
//               {t("insulinfactor")} {handleUserDayFactor(insulinFactor)}
//             </FactorOption>
//           </FactorSelect>
//           <FactorSelect
//             name="correctionfactorSelect"
//             id="correctionfactorSelect"
//             value={handleUserCorrectionFactor(correctionFactors)}
//           >
//             <FactorOption value={handleUserCorrectionFactor(correctionFactors)}>
//               {t("correction")} {handleUserCorrectionFactor(correctionFactors)}
//             </FactorOption>
//           </FactorSelect>
//         </LabelFa>
//       </EntryForm>
//     </>
//   )
// }


import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"
import { Storage } from "@capacitor/storage"
import {
  EntryForm,
  LabelBz,
  BloodDrop,
  Blood,
  DataInput,
  LabelCa,
  Carbohydrates,
  Donut,
  LabelIu,
  InsulinSelect,
  InsulinOption,
  LabelFa,
  FactorSelect,
  FactorOption,
  Button,
  InsulinUnits,
  FullInject,
  EmptyInject,
  SuccessMessage,
} from "./HomeInputStyles"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Loading from "../PageLoader/PageLoader"
import handleInsulinUnit from "../../utils/calculate"

const saveToLocalStorage = async (key, value) => {
  await Storage.set({
    key,
    value: JSON.stringify(value),
  })
}

const getFromLocalStorage = async (key) => {
  const result = await Storage.get({ key })
  return result.value ? JSON.parse(result.value) : null
}

export default function HomeInput() {
  const { user, isLoading } = useAuth0()
  const { t } = useTranslation()
  const [bloodsugar, setBloodsugar] = useState("")
  const [carbohydrates, setCarbohydrates] = useState("")
  const [insulin, setInsulin] = useState("")
  const [value, setValue] = useState("")
  const [showPopup, setShowPopup] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [formData, setFormData] = useState(null)
  const [insulinFactor, setInsulinFactor] = useState(null)
  const [correctionFactors, setCorrectionFactors] = useState(null)

  useEffect(() => {
    const fetchFactors = async () => {
      const localInsulinFactor = await getFromLocalStorage("insulinFactors")
      const localCorrectionFactors = await getFromLocalStorage("correctionFactors")

      if (localInsulinFactor) setInsulinFactor(localInsulinFactor)
      if (localCorrectionFactors) setCorrectionFactors(localCorrectionFactors)

      if (navigator.onLine && user) {
        try {
          const insulinResponse = await fetch(`https://insulinapp-api.vercel.app/api/daytimefactors/${user.email}`)
          const correctionResponse = await fetch(
            `https://insulinapp-api.vercel.app/api/correctionfactors/${user.email}`,
          )

          if (insulinResponse.ok) {
            const insulinData = await insulinResponse.json()
            setInsulinFactor(insulinData)
            await saveToLocalStorage("insulinFactors", insulinData)
          }

          if (correctionResponse.ok) {
            const correctionData = await correctionResponse.json()
            setCorrectionFactors(correctionData)
            await saveToLocalStorage("correctionFactors", correctionData)
          }
        } catch (error) {
          console.error("Error fetching factors:", error)
        }
      }
    }

    fetchFactors()
  }, [user])

  if (isLoading || !user || !insulinFactor || !correctionFactors) return <Loading />

  function handleUserDayFactor(insulinFactor) {
    const currentHour = new Date().getHours()
    if (currentHour >= 6 && currentHour < 11) return insulinFactor?.morningfactor || 1
    if (currentHour >= 11 && currentHour < 17) return insulinFactor?.lunchfactor || 1
    if (currentHour >= 17 && currentHour < 22) return insulinFactor?.eveningfactor || 1
    return insulinFactor?.latefactor || 1
  }

  function handleUserCorrectionFactor(correctionFactors) {
    const currentHour = new Date().getHours()
    if (currentHour >= 6 && currentHour < 11) return correctionFactors?.morningcorrectionfactor || 1
    if (currentHour >= 11 && currentHour < 17) return correctionFactors?.lunchcorrectionfactor || 1
    if (currentHour >= 17 && currentHour < 22) return correctionFactors?.eveningcorrectionfactor || 1
    return correctionFactors?.latecorrectionfactor || 1
  }

  async function handleSubmit(event) {
    event.preventDefault()

    const daytimeFactor = handleUserDayFactor(insulinFactor)
    const correctionFactor = handleUserCorrectionFactor(correctionFactors)
    const calculateUnits = handleInsulinUnit(bloodsugar, carbohydrates, daytimeFactor, correctionFactor)

    setFormData({ bloodsugar, carbohydrates, insulin, daytimeFactor, correctionFactor, calculateUnits })
    setValue(calculateUnits)
    setShowPopup(true)
  }

  async function confirmSave() {
    const cardData = {
      bloodsugar: formData.bloodsugar,
      carbohydrates: formData.carbohydrates,
      insulin: formData.insulin,
      daytimeFactor: formData.daytimeFactor,
      correctionFactor: formData.correctionFactor,
      calculateUnit: formData.calculateUnits,
      userMail: user.email,
      date: new Date().toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
    }

    await saveOfflineData(cardData)

    if (navigator.onLine) {
      try {
        const response = await fetch("https://insulinapp-api.vercel.app/api/insulindata", {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(cardData),
        })

        if (!response.ok) {
          throw new Error("Failed to save data")
        }
      } catch (error) {
        console.error("Error saving data:", error)
      }
    }

    resetForm()
  }

  async function saveOfflineData(data) {
    const existingData = (await getFromLocalStorage("insulinData")) || []
    const newData = [...existingData, data]
    await saveToLocalStorage("insulinData", newData)
  }

  function resetForm() {
    setBloodsugar("")
    setCarbohydrates("")
    setInsulin("")
    setTimeout(() => setValue(""), 15000)
    setShowPopup(false)
    setShowSuccess(true)
    setTimeout(() => setShowSuccess(false), 3000)
  }

  return (
    <>
      {showPopup && <ConfirmationPopup data={formData} onConfirm={confirmSave} onCancel={() => setShowPopup(false)} />}
      {showSuccess && <SuccessMessage className="success-message">{t("dataSaved")}</SuccessMessage>}

      <EntryForm onSubmit={handleSubmit}>
        <LabelBz htmlFor="bloodsugar">
          {t("bloodsugar")} <br /> mg/dl
          <BloodDrop src="/blooddrop.PNG" alt="blooddrop" />
          <Blood src="/blood.PNG" alt="blood" />
          <DataInput
            type="decimal"
            name="bloodsugar"
            placeholder={t("currentbloodglucosevalue")}
            id="bloodsugar"
            min={0}
            inputMode="numeric"
            maxLength={3}
            required
            value={bloodsugar}
            onChange={(e) => setBloodsugar(e.target.value)}
          />
        </LabelBz>
        <LabelCa htmlFor="carbohydrates">
          {t("carbohydrates")} (Khd) <br /> g (Gramm)
          <Carbohydrates src="/carbohydratesImg.png" alt="carbohydrates" />
          <Donut src="/donut.png" alt="donut" />
          <DataInput
            type="decimal"
            name="carbohydrates"
            placeholder={t("howmanyKhd65g")}
            id="carbohydrates"
            maxLength={3}
            min={0}
            inputMode="numeric"
            required
            value={carbohydrates}
            onChange={(e) => setCarbohydrates(e.target.value)}
          />
        </LabelCa>
        <LabelIu htmlFor="insulin">
          {t("whichinsulin1")} <br /> {t("whichinsulin2")}
          <InsulinSelect
            name="setinsulinSelect"
            id="setinsulinSelect"
            value={insulin}
            onChange={(e) => setInsulin(e.target.value)}
          >
            <InsulinOption value="">{t("chooseinsulin")}</InsulinOption>
            {[
              { value: "Fiasp", label: "Fiasp" },
              { value: "Hum Normal", label: "Hum Normal" },
            ].map((option) => (
              <InsulinOption key={option.value} value={option.value}>
                {option.label}
              </InsulinOption>
            ))}
          </InsulinSelect>
        </LabelIu>
        <Button type="submit">{t("confirm")}</Button>
        <InsulinUnits>
          {value} / {t("injectunits")} <br /> {t("ofinsulin")}
          <FullInject src="/volleSpritze.png" alt="Full syringe" />
          <EmptyInject src="/leereSpritze.png" alt="Empty syringe" />
        </InsulinUnits>
        <LabelFa htmlFor="factor">
          {t("daytimefactor")}
          <FactorSelect name="dayfactorSelect" id="dayfactorSelect" value={handleUserDayFactor(insulinFactor)}>
            <FactorOption value={handleUserDayFactor(insulinFactor)}>
              {t("insulinfactor")} {handleUserDayFactor(insulinFactor)}
            </FactorOption>
          </FactorSelect>
          <FactorSelect
            name="correctionfactorSelect"
            id="correctionfactorSelect"
            value={handleUserCorrectionFactor(correctionFactors)}
          >
            <FactorOption value={handleUserCorrectionFactor(correctionFactors)}>
              {t("correction")} {handleUserCorrectionFactor(correctionFactors)}
            </FactorOption>
          </FactorSelect>
        </LabelFa>
      </EntryForm>
    </>
  )
}


