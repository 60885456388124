import styled from 'styled-components';
import { keyframes } from 'styled-components';


export const WrapperSaved = styled.section`
  display: grid;
  justify-content: center;
  height: 90vh;
  overflow: hidden; // Verhindert Scrollen im Hauptcontainer
  z-index: 3;
  opacity: 0.75;
  background-attachment: scroll;
  padding-bottom: 0.7rem; // Entfernen Sie den unteren Rand
  margin-top: 0; // Entfernen Sie den oberen Rand
`

export const CardGrid = styled.ul`
  display: grid;
  gap: auto;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-content: start;
  margin: 0;
  padding: 0;
  overflow-y: auto; // Ermöglicht vertikales Scrollen
  border-radius: 10px;
  justify-content: center;
  flex-grow: 1; // Erlaubt dem Grid zu wachsen und den verbleibenden Platz zu füllen
`

// Fügen Sie diese neue Komponente hinzu:

export const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  padding-top: 3rem; // Ersetzt den entfernten oberen Rand von WrapperSaved
  padding-bottom: 1rem; // Fügt etwas Abstand zum CardGrid hinzu
`


export const Saved = styled.li`
  display: grid;
  background-color: beige;
  border-radius: 8px;
  text-align: center;
  height: auto;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
`;

export const Summary = styled.summary`
  &::-webkit-details-marker {
    display: none;
  }
  display: grid;
  text-decoration: underline;
  background-color: transparent;
  border-radius: 8px;
  text-align: center;
  height: auto;
  align-items: center;
`;

export const Details = styled.details`
  display: grid;
  background-color: beige;
  right: 1rem;
  border-radius: 8px;
  text-align: center;
  height: auto;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
`;

export const BloodSugar = styled.span`
  color: #c92a2a;
`;

export const Insulin = styled.span`
  color: #5c940d;
`;

export const Carbohydrates = styled.span`
  color: #e67700;
`;

export const Factor = styled.span`
  color: #2b8a3e;
`;

export const InsulinUnits = styled.span`
  color: #364fc7;
`;

export const Timestampwraper = styled.span`
  color: #008080;
  font-size: 0.8rem;
  display: grid;
  margin-top: 0.3rem;
`;

export const SearchIconWrapper = styled.span`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  color: #fe4b13;
  font-size: 1.4rem; 
  cursor: pointer;
  margin-top: 0.1rem; 
  color: black;
`;

export const SearchData = styled.span`
  display: grid;
  margin: 0.3rem;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  color: #fff;
  width: 1rem;
`;

export const DataInput = styled.input`
  width: auto;
  text-align: center;
  height: 2.2rem;
  border-radius: 8px;
  background-color: beige;
  color: black;
  border: none;
  opacity: 0.85;
  position: absolute;
  z-index: 50;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const CheckBox = styled.div`
  display: flex;
  justify-items: left;
  height: 1rem;
  width: 1rem;
  margin-bottom: -0.5rem;
  cursor: pointer;
`;

export const CheckBoxInput = styled.input`
  width: auto;
  text-align: center;
  height: 2.2rem;
  border-radius: 8px;
  background-color: beige;
  color: black;
  border: none;
  opacity: 0.85;
`;

export const Button = styled.button`
  background-color: cadetblue;
  color: black;
  font-family: monospace;
  font-size: larger;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  height: 1.8rem;
  margin: 0.4rem;
  width: 14.3rem;
  cursor: pointer;
`;

const hue = keyframes`
 from {
   -webkit-filter: hue-rotate(180deg);
 }
 to {
   -webkit-filter: hue-rotate(-180deg);
 }
`;

export const SavedData = styled.h2`
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: ${hue} 10s infinite linear;
  color: purple;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 3rem;
  z-index: 1;
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  color: #fe4b13;
  margin-top: -1rem;
`;

export const CountDisplay = styled.div`
  font-family: monospace;
  font-size: medium;
  color: black;
  text-align: center;
  background-color: cadetblue;
  border-radius: 10px;
  padding: 0.3rem ;
  height: 1.8rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  opacity: 0.9;
  width: 14.3rem;
`;

export const DeleteIcon = styled(IconWrapper)`
justify-content: start;
`;


